import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Navigate, Link } from 'react-router-dom';
import AuthService from "../services/AuthService";


const OptionItems = ({ tenants }) => {
    let items = [];
    items.push(<option key="0000-0000-0000-0000" value=""></option>);
    tenants.map(tenant => {
        items.push(<option key={tenant.id} value={tenant.id}>{tenant.name}</option>);
    })

    return (
        items
    );
}

const RoleOptionItems = ({ role }) => {
    let items = [];
    items.push(<option key="0000-0000-0000-0000" value=""></option>);

    //if (role == "SystemAdmin") {
    //    items.push(<option key="0000-0000-0000-0001" value="SystemAdmin">SystemAdmin</option>);
    //}
    items.push(<option key="0000-0000-0000-0002" value="Admin">Admin</option>);
    items.push(<option key="0000-0000-0000-0003"  value="Viewer">Viewer</option>);

    return (
        items
    );
}

export class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: '', email: '', tenant: '', role: '', tenantname: '', message: '', loading: '', redirect: '', tenants: [], classname: ''
        }

        this.changeFullNameHandler = this.changeFullNameHandler.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.handleChangeTenant = this.handleChangeTenant.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Create User";
        const user = AuthService.getLoggedInUser();        
        this.setState({ currentuser: user });
        this.getTenants(user);        
        if (user.roles.includes("Viewer")) this.setState({ redirect: '/denied' });
    }

    changeFullNameHandler = (e) => {
        e.preventDefault();
        this.setState({ fullName: event.target.value });
    }

    changeEmailHandler = (e) => {
        e.preventDefault();
        this.setState({ email: event.target.value });
    }

    handleChangeTenant = (e) => {
        e.preventDefault();
        console.log(e.target.options[e.target.selectedIndex].text);

        this.setState({
            tenant: e.target.value,
            tenantname: e.target.options[e.target.selectedIndex].text
        });
    }

    handleChangeRole = (e) => {
        e.preventDefault();
        this.setState({ role: e.target.value });
    }

    async addUser() {
        event.preventDefault();
        if (!this.state.email || !this.state.fullName || !this.state.tenant || !this.state.role) {
            this.setState({ message: 'Please enter all mandatory fields', classname: 'alert alert-danger' })
            return;
        }
        this.setState({ classname: '', message:'' })
        let model = {
            fullName: this.state.fullName, email: this.state.email,
            tenant: this.state.tenantname,
            tenantId: this.state.tenant, role: this.state.role
        }

        const user = AuthService.getLoggedInUser();       
        this.setState({ loading: 'Processing. please wait..' });
        const response = await fetch("api/users", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            },
            body: JSON.stringify(model)
        });

        var data = await response.json();
        this.setState({ message: data.message, loading: '', classname: response.ok ? 'alert alert-success' : 'alert alert-danger' });
    }

    getTenants(user) {
        let temp = [];
        //temp.push({ id: '758bbfb8-8120-44a3-b010-75e42c42614a', name: 'Base Tenant'})
        //temp.push({ id: '6a9fd455-5f1a-4158-b6ff-1454d22debd0', name: 'Team X'})
        //temp.push({ id: '2b444071-9d2e-4345-a325-2b8dafd9f414', name: 'Content Query UI'})
        //this.setState({ tenants: temp });
        //return;
        
        fetch("api/tenants", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            }
        }).then((response) => response.json()).then((data) => {
            this.setState({ tenants: data });
        });
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        let user = AuthService.getLoggedInUser();
        return (
            <div>
                <Row>
                    <Col md="12">
                        <h5><i className="fa fa-users" aria-hidden="true"></i> <Link to="/users">Users</Link> > New User </h5><hr />
                    </Col>

                    <Col md="12">
                        <Form>
                            <FormGroup>
                                <Label for="txt-full-name">Full Name<span className="text-danger"> *</span></Label>
                                <Input type="text" name="txt-full-name" id="txt-full-name"
                                    onChange={this.changeFullNameHandler} placeholder="John Doe"
                                    value={this.state.fullName} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="txt-email">Email<span className="text-danger"> *</span></Label>
                                <Input type="email" name="txt-email" id="txt-email" placeholder="john.doe@contentquery.io"
                                    onChange={this.changeEmailHandler}
                                    value={this.state.email} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="select-tenant">Select Tenant<span className="text-danger"> *</span></Label>
                                <Input type="select" name="select-tenant" id="select-tenant" onChange={this.handleChangeTenant}>
                                    <OptionItems tenants={this.state.tenants} />
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="select-role">Select Role<span className="text-danger"> *</span></Label>
                                <Input type="select" name="select-role" id="select-role" onChange={this.handleChangeRole}>
                                    <RoleOptionItems role={user.roles[0]} />
                                </Input>
                            </FormGroup>
                            <p></p>
                            
                            <Button onClick={async () => { await this.addUser(); }} className="btn btn-primary mt-3">Submit</Button>
                        </Form>

                        <div className="pt-4"><p><em>{this.state.loading}</em></p></div>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>

            </div>
        );
    }
}