import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Navigate, Link } from 'react-router-dom';
import withParamRouter from '../common/WithParamRouter';
import AuthService from "../services/AuthService";


const OptionItems = ({ tenants, id }) => {
    let items = [];
    let first = tenants.find(w => w.id === id);

    items.push(<option key={first.id} value={first.id}>{first.name}</option>);

    tenants.map(tenant => {
        if (tenant.id != id)
        items.push(<option key={tenant.id} value={tenant.id}>{tenant.name}</option>);
    })

    return (
        items
    );
}

const RoleOptionItems = ({ role }) => {
    let items = [];
    const roles = ["Admin", "Viewer"];
    roles.map(r => {        
        
            items.push(<option key={r} value={r}>{r}</option>);
        
    })

    return (
        items
    );
}

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selecteduser: undefined, fullName: '', email: '', tenant: '', role: '', tenantname: '', message: '', loading: true,
            redirect: '', tenants: [], tenantid: '', classname: ''
        }

        this.changeFullNameHandler = this.changeFullNameHandler.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.handleChangeTenant = this.handleChangeTenant.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.updateUser = this.updateUser.bind(this);
    }

    componentDidMount() {

        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Edit User";
        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user });
            if (user.roles.includes("Viewer")) this.setState({ redirect: '/denied' });
            this.getTenants(user);
            this.getUser(this.props.params.id, user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    changeFullNameHandler = (e) => {
        e.preventDefault();
        var user = this.state.selecteduser;
        user.fullName = e.target.value;
        this.setState({ selecteduser: user });
    }

    changeEmailHandler = (e) => {
        e.preventDefault();
        var user = this.state.selecteduser;
        user.email = e.target.value;
        this.setState({ selecteduser: user });

    }

    handleChangeTenant = (e) => {
        e.preventDefault();
        var user = this.state.selecteduser;
        user.tenantId = e.target.value;
        user.tenant = e.target.options[e.target.selectedIndex].text;
        this.setState({ selecteduser: user });
    }

    handleChangeRole = (e) => {
        e.preventDefault();
        var user = this.state.selecteduser;
        user.role = e.target.value;
        this.setState({ selecteduser: user });
    }

    async updateUser() {
        event.preventDefault();
        if (!this.state.selecteduser.email
            || !this.state.selecteduser.fullName
            || !this.state.selecteduser.tenant
            || !this.state.selecteduser.role) {
            this.setState({ message: 'Please enter all mandatory fields', classname: 'alert alert-danger' })
            return;
        }

        const user = AuthService.getLoggedInUser();
        this.setState({ message: 'Processing. please wait..' });
        const response = await fetch("api/users", {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            },
            body: JSON.stringify(this.state.selecteduser)
        });

        var data = await response.json();
        this.setState({ message: data.message, classname: response.ok ? 'alert alert-success' : 'alert alert-danger' });
    }

    getTenants(user) {

        fetch("api/tenants", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            }
        }).then((response) =>            
            response.json()).then((data) =>
            {                
                this.setState({ message: data.message != undefined ? data.message : '' });                 
                this.setState({ tenants: data.message == undefined  ? data : []});                 
            });
    }

    async getUser(id, user) {
        const response = await fetch("api/users/" + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token
            }
        });

        const data = await response.json();
        if (response.ok) {
            this.setState({ selecteduser: data, tenantid: data.tenantId })
        }

        else {
            this.setState({ message: data.message });
        }

        this.setState({ loading: false })
    }

    renderUser(user) {

        if (this.state.tenants.length == 0) {
            return (
                <Row>
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>)
        }

        return (
            <div>
                
                <Row>
                    <Col md="12">
                        <h5><i className="fa fa-users" aria-hidden="true"></i> <Link to="/users">Users</Link> > Edit User </h5><hr />
                    </Col>

                    <Col md="12">
                        <Form>
                            <FormGroup>
                                <Label for="txt-full-name">Full Name<span className="text-danger"> *</span></Label>
                                <Input type="text" name="txt-full-name" id="txt-full-name"
                                    onChange={this.changeFullNameHandler} placeholder="John Doe"
                                    value={user.fullName} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="txt-email">Email<span className="text-danger"> *</span></Label>
                                <Input type="email" name="txt-email" id="txt-email"
                                    onChange={this.changeEmailHandler} placeholder="john.doe@contentquery.io"
                                    value={user.email} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="select-tenant">Select Tenant<span className="text-danger"> *</span></Label>
                                <Input type="select" value={user.tenantid} name="select-tenant" id="select-tenant" onChange={this.handleChangeTenant}>
                                    <OptionItems tenants={this.state.tenants} id={user.tenantId} />
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="select-role">Select Role<span className="text-danger"> *</span></Label>
                                <Input type="select" value={user.role}  name="select-role" id="select-role" onChange={this.handleChangeRole}>
                                    <RoleOptionItems role={user.role} />
                                </Input>
                            </FormGroup>

                            <Button onClick={async () => { await this.updateUser(); }} className="btn btn-primary mt-3">Submit</Button>
                        </Form>

                        <div className="pt-4"><p><em>{this.state.loading}</em></p></div>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>

            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderUser(this.state.selecteduser);
        return (
            <div>
                {contents}
            </div>
        );
    }
}
export default withParamRouter(EditUser)