import React, { Component } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler,
    NavItem, NavLink, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem
} from 'reactstrap';
import './css/custom.css'
import Login from './components/Login';
import { Campaigns } from './components/Campaigns';
import  CampaignAssets from './components/CampaignAssets';
import { Assets } from './components/Assets';
import { Dashboard } from './components/Dashboard';
import { SearchAssets } from './components/SearchAssets';
import { Tenants } from './components/Tenants';
import { CreateTenant } from './components/CreateTenant';
import { Users } from './components/Users';
import { CreateUser } from './components/CreateUser';
import EditAsset  from './components/EditAsset';
import AuthService from './services/AuthService';
import EventBus from './common/EventBus';
import { NotAuthorize } from './components/NotAuthorize';
import { NotFound } from './components/NotFound';
import { Profile } from './components/Profile';
import { Settings } from './components/Settings';
import EditTenant from './components/EditTenant';
import EditUser from './components/EditUser';
import { Logout } from './components/Logout';
import { Report } from './components/Report';
 

export default class App extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);        
        this.state = { currentuser: undefined, showtenants: false, showusers: false, redirect: null }        
    }

    componentDidMount() {
        const user = AuthService.getLoggedInUser();        
        if (user) {
            this.setState({
                currentuser: user,
                showtenants: user.roles.includes("SystemAdmin"),
                showusers: !user.roles.includes("Viewer")                
            });
        }

        EventBus.on("logout", () => {
            this.logOut();
        });
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {         
        
        this.setState({
            currentuser: undefined,
            showtenants: false,
            showusers: false,
            showassetdelete: false
        });

        AuthService.logOut();
       
    }

    render() {
        const { currentuser, showtenants, showusers } = this.state;
        
        return (
            <div>
                {currentuser ? (

                    <header className="card-shadow">
                        <Navbar className="navbar navbar-expand navbar-toggleable-sm ng-black border-bottom fixed-top box-shadow mb-5 navbar-light bg-light">

                            <Container>
                                {/*<NavbarBrand tag={Link} to="/"><i className="fa fa-ravelry text-danger" aria-hidden="true"></i> Content Query</NavbarBrand>*/}

                                <NavbarBrand tag={Link} to="/dashboard"><h4>Content Query</h4></NavbarBrand>
                                 
                                <Collapse id="cq-nav-bar" className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>

                                    <ul className="navbar-nav flex-grow">
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/dashboard"><i className="fa fa-home fa-fw" aria-hidden="true"></i> Dashboard</NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/campaigns"><i className="fa fa-bullhorn" aria-hidden="true"></i> Campaigns</NavLink>
                                        </NavItem>

                                        {/*<NavItem>*/}
                                        {/*    <NavLink tag={Link} className="text-dark" to="/assets"><i className="fa fa-picture-o" aria-hidden="true"></i> Assets</NavLink>*/}
                                        {/*</NavItem>*/}
 
                                        
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/search"><i className="fa fa-search" aria-hidden="true"></i> Search</NavLink>
                                        </NavItem>


                                        {/*<NavItem>*/}
                                        {/*    <NavLink tag={Link} className="text-dark" to="/report"><i className="fa fa-file-text" aria-hidden="true"></i> Report</NavLink>*/}
                                        {/*</NavItem>*/}



                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret className="text-dark">
                                                <span><i className="fa fa-th-large" aria-hidden="true"></i> Admin</span>
                                            </DropdownToggle>
                                            <DropdownMenu right>

                                                {showusers && (
                                                    <DropdownItem>
                                                        <NavLink tag={Link} className="text-dark" to="/users"><i className="fa fa-users" aria-hidden="true"></i> Users</NavLink>
                                                    </DropdownItem>
                                                )}

                                                {showtenants && (
                                                    <DropdownItem>
                                                        <NavLink tag={Link} className="text-dark" to="/tenants"><i className="fa fa-list" aria-hidden="true"></i> Tenants</NavLink>
                                                    </DropdownItem>
                                                )}

                                                {showtenants && (
                                                    <DropdownItem>
                                                        <NavLink tag={Link} className="text-dark" to="/settings"><i className="fa fa-gear" aria-hidden="true"></i> Settings</NavLink>
                                                    </DropdownItem>
                                                )}

                                            </DropdownMenu>

                                        </UncontrolledDropdown>

                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav>
                                                <span><i className="fa fa-user-circle" aria-hidden="true"/> </span>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/profile"><i className="fa fa-user" aria-hidden="true"></i> Profile</NavLink>
                                                </DropdownItem>
                                                
                                                <DropdownItem divider />
                                                <DropdownItem>
                                                    <NavLink tag={Link} className="text-dark" to="/logout" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ul>
                                    
                                </Collapse>
                            </Container>
                        </Navbar>
                    </header>

                ) : (
                    <header className="card-shadow">
                        <Navbar className="navbar navbar-expand navbar-toggleable-sm ng-black border-bottom fixed-top box-shadow mb-5 navbar-light bg-light">

                            <Container>
                                {/*<NavbarBrand tag={Link} to="/"><i className="fa fa-ravelry text-danger" aria-hidden="true"></i> Content Query</NavbarBrand>*/}

                                <NavbarBrand tag={Link} to="/login"><h4>Content Query</h4></NavbarBrand>
                            </Container>
                        </Navbar>
                    </header>

                )}

                <Container  id="_main" style={{ marginTop: "100px" }}>
                    <Routes>
                        <Route path='/'  element={<Dashboard />} />
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/campaigns' element={<Campaigns />} />
                        <Route path='/assets/:id' element={<CampaignAssets />} />
                        <Route path='/assets' element={<Assets />} />
                        <Route path='/editasset' element={<EditAsset />} />
                        <Route path='/tenants' element={<Tenants />} />
                        <Route path='/add-tenant' element={<CreateTenant />} />
                        <Route path='/edit-tenant/:id' element={<EditTenant />} />
                        <Route path='/users' element={<Users />} />
                        <Route path='/add-user' element={<CreateUser />} />
                        <Route path='/edit-user/:id' element={<EditUser />} />
                        <Route path='/search' element={<SearchAssets />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/report' element={<Report />} />
                        <Route path='/logout' element={<Logout /> } />
                        <Route path='/denied' element={<NotAuthorize />} />
                        <Route path='*' element={<NotFound />} />
               
                    </Routes>
                </Container>

            </div>
        );
    }
}