import React, { Component} from 'react';

export class Settings extends Component {

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        document.title = "Settings";
    }

    render() {
        return (
            <div>
                <div>
                    <h5><i className="fa fa-gear" aria-hidden="true"></i> Settings</h5><hr />
                </div>

                <div><h5>Settings</h5></div>
            </div>
            );
    }   
}
