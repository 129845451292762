import React, { Component } from 'react';
import 'webdatarocks/webdatarocks.css'
import * as WebDataRocksReact from 'react-webdatarocks';
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import AuthService from "../services/AuthService";


const customizeToolbar = (toolbar) => {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
        delete tabs[0];
        delete tabs[1];
        delete tabs[2];
        return tabs;
    };
};

export class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assetlist: null, loading: true, hasaccess: false, message: null,
            redirect: null, currentuser: undefined, reporturl: 'https://deft-alpaca-0735e7.netlify.app/campaign-assets.json'
        }
        this.changeReport = this.changeReport.bind(this);
    }

    componentDidMount() {
        document.title = "Reports";

        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container-fluid");


        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user, hasaccess: user.roles.includes("Admin") });
        }
        else {
            this.setState({ redirect: '/login' });
        }
 
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        return (
            <div>
                <div>
                    <h5><i className="fa fa-file-text" aria-hidden="true"></i> Reports</h5><hr />
                </div>

                <Row>
                    <Col md="12" className="text-center">
                        <UncontrolledDropdown className="me-2" direction="down">
                            <DropdownToggle caret color="primary">
                                Select Report
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.changeReport(1); }}>
                                    Campaign Asset Report
                                </DropdownItem>
                                <DropdownItem onClick={() => { this.changeReport(2); }}>
                                    Asset Tag Report
                                </DropdownItem>

                                <DropdownItem divider />
                                <DropdownItem>
                                    Advance Report
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                    <Col md="12">
                        <div id="wdr-component"></div>
                    </Col>

                </Row>
            </div>
        );
    }

    changeReport(id) {
        
        var url = id == 1 ? "https://deft-alpaca-0735e7.netlify.app/campaign-assets.json" : "https://deft-alpaca-0735e7.netlify.app/campaign-assets-tags.json"
        new WebDataRocks({
            container: "#wdr-component",
            toolbar: true,
            height: 750,
            beforetoolbarcreated: customizeToolbar,
            report: url
        });

        this.setState({ reporturl : url })
    }
}
