import React, { Component } from 'react';
import { Row, Col, Table, Button, Input } from 'reactstrap';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import AuthService from "../services/AuthService";


const AddButton = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/add-user');
    }
    return <button className="btn btn-dark pull-right m-2" onClick={handleClick}>Add User</button>;
}

export class Users extends Component { 

    constructor(props) {
        super(props);
        this.state = {
            userList: [], loading: true, redirect: null,
            currentuser: undefined, message: '', className: '', allUsers : []
        }
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        document.title = "Users"
        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user, });
            if (user.roles.includes("Viewer")) this.setState({ redirect: '/denied' });
            if (!this.state.redirect) this.getUsers(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    handleSearch(e) {

        if (e.target.value.length == 0) {
            this.setState({ userList: this.state.allUsers })
        }
        else {
            let result = this.findUserByNameAndEmail(this.state.allUsers, e.target.value.toLowerCase());            
            this.setState({ userList: result })
        }
    }

    findUserByNameAndEmail(users, query) {
        let result = [];
        users.map(function (element) {
            if (element.fullName.toLowerCase().startsWith(query) || element.email.toLowerCase().includes(query)) {
                result.push(element);
            }
        });

        return result;
    }

    renderUsers(users) {

        if (users == undefined) {
            return (
                <div>
                    <Row>
                        <p></p>
                        <Col md="12" className={this.state.className}>
                            <span>{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <Row>
                    <p></p>
                    <Col md="12" className={this.state.className}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="mb-1">
                        <AddButton />   
                        
                    </Col>

                    <Col md="12" className="mb-2">
                        <Input id="query" placeholder="Search" onChange={this.handleSearch} />
                    </Col>

                    <Col md="12">
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Tenant</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user =>
                                    <tr key={user.id}>
                                        <td>{user.fullName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>{user.tenant}</td>
                                        <td>{user.state == "L" ? "Locked" : "Active"}</td>
                                        <td align="right">
                                            <Link className="btn btn-sm btn-primary ml-1" to={"/edit-user/" + user.id} state={{ id: user.id }}><i className="fa fa-edit"></i></Link>

                                            <Button className="btn btn-sm btn-danger ml-1" onClick={async () => { await this.deleteUser(user.id); }}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
               
            </div>
        );
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to='/denied' />
        }

        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderUsers(this.state.userList);
        return (
            <div>
                <h5><i className="fa fa-users" aria-hidden="true"></i> Users ({this.state.userList ? this.state.userList.length : 0})</h5><hr />
                {contents}
            </div>
        );
    }

    async getUsers(user) {
        const response = await fetch("api/users", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token                
            }
        });
        
        const data = await response.json();        
        if (response.ok) {
            this.setState({ userList: data, loading: false, allUsers : data })
        }
        else {
            this.setState({ message: data.message, loading: false, className:'alert alert-danger' });
        }        
    }

    async deleteUser(id) {
        if (window.confirm('Are you sure you want to remove this user?')) {
            const user = AuthService.getLoggedInUser();
            const response = await fetch("api/users/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + user.token
                    }
                });

            if (response.ok) {
                const list = this.state.userList.filter(user => user.id !== id);
                this.setState({ classname: 'alert alert-sucess',  userList: list });
            }
            else {
                this.setState({ classname: 'alert alert-danger', message: 'Something went wrong. Unable to delete selected Tenant, Please try again.' });
            }
        }
    }

}