import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Navigate, Link } from 'react-router-dom';
import AuthService from "../services/AuthService";

export class CreateTenant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '', loading: '', tenantname: '', callback: '',
            redirect: '', currentuser: undefined, clientid: '', clientsecret: '',
            classname: '', message: '', supportemail: '', mask: '********-****-****-****-************',
            headers: '', isinternal:false
        }
        this.changeTenantNameHandler = this.changeTenantNameHandler.bind(this);
        this.changeCallbackHandler = this.changeCallbackHandler.bind(this);
        this.changeSupportEmailHandler = this.changeSupportEmailHandler.bind(this);
        this.addTenant = this.addTenant.bind(this);
        this.onShowSecret = this.onShowSecret.bind(this);
        this.onInteralCheck = this.onInteralCheck.bind(this);
    }

    changeTenantNameHandler = (e) => {
        e.preventDefault();
        this.setState({ tenantname: e.target.value });
    }
    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Create Tenant";
        const user = AuthService.getLoggedInUser();
        this.setState({ currentuser: user, clientid: this.createUUID(), clientsecret: this.createUUID() });
        if (!user.roles.includes("SystemAdmin")) this.setState({ redirect: '/denied' });
    }

    changeCallbackHandler = (e) => {
        e.preventDefault();
        this.setState({ callback: e.target.value });
    }

    changeSupportEmailHandler = (e) => {
        e.preventDefault();
        this.setState({ supportemail: e.target.value });
    }

    changeHeaderHandler = (e) => {
        e.preventDefault();
        this.setState({ headers: e.target.value });
    }

    createUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });        
        return uuid;
    }

    onShowSecret() {
        this.setState({ mask: this.state.clientsecret });
    }
    onInteralCheck(val) {
        this.setState({ isinternal: val })        
    }

    async addTenant() {
        event.preventDefault();

        if (!this.state.tenantname || !this.state.clientid || !this.state.clientsecret || !this.state.supportemail) {
            this.setState({ message: 'Please enter all mandatory fields', classname: 'alert alert-danger' })
            return;
        }

        let model = {
            name: this.state.tenantname, callbackUrl: this.state.callback, clientid: this.state.clientid,
            clientsecret: this.state.clientsecret, supportemail: this.state.supportemail, headers: this.state.headers,
            isinternal: this.state.isinternal
        }
        this.setState({ loading: 'Processing. please wait..' });
        const user = AuthService.getLoggedInUser();    
        const response = await fetch("api/tenants", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            },
            body: JSON.stringify(model)
        });

        var data = await response.json();
 
        if (response.ok) {
            this.setState({ loading: '', message: data.message, classname: 'alert alert-success' })
        }
        else {
            this.setState({ message: data.message, classname: 'alert alert-danger', loading: '' })
        }
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        return (
            <div>
                <Row>
                    <Col md="12">
                        <h4><i className="fa fa-list" aria-hidden="true"></i> <Link to="/tenants">Tenants</Link> > New Tenant </h4><hr />
                    </Col>

                    <Col md="12">
                        <Form>
                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-name">Name<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-name" id="txt-name"
                                            placeholder="Content Query"
                                            onChange={this.changeTenantNameHandler} value={this.state.tenantname} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-client-id">Client ID<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-client-id" id="txt-clientid" value={this.state.clientid} disabled />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-client-secret">Client Secret<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-client-secret" id="txt-client-secret"
                                            value={this.state.mask} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <Button onClick={this.onShowSecret} className="btn btn-sm" style={{ marginTop: "35px" }}>Show</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-supportemail">Support Email<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-supportemail" id="txt-supportemail"
                                            onChange={this.changeSupportEmailHandler} placeholder="support@contentquery.io"
                                            value={this.state.supportemail} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-callbackurl">Notification Url<span className="text-danger"></span></Label>
                                        <Input type="text" name="txt-callbackurl" id="txt-callbackurl"
                                            onChange={this.changeCallbackHandler} placeholder="https://companydomain.com/notify"
                                            value={this.state.callback} />

                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-headers">Headers (key:value)<span className="text-danger"></span></Label>
                                        <Input type="textarea" name="txt-headers" id="txt-headers"
                                            placeholder="e.g. x-api-key:5b5dbd32cdb44a4fa9a436c6e4937baf"
                                            onChange={this.changeHeaderHandler}
                                            value={this.state.headers} />

                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <Button className="btn btn-sm" style={{ marginTop: "35px" }}>Ping</Button>
                                </Col>
                                <Col md="11">
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="checkbox"
                                                value={this.state.isinternal}
                                                onChange={(e) => this.onInteralCheck(e.target.checked)} /> Internal Client
                                        </Label>
                                    </FormGroup>
                                </Col>

                                
                            </Row>


                            <p></p>

                            <Button onClick={async () => { await this.addTenant(); }} className="btn btn-primary">Submit</Button>
                        </Form>

                        <div className="pt-4"><p><em>{this.state.loading}</em></p></div>
                    </Col>

                    
                </Row>
                <Row className="p-2">
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>
                
            </div>
        );
    }
}