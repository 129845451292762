import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button, Container } from 'reactstrap';
import { withRouter } from '../common/WithRouter';
import AuthService from "../services/AuthService";


class Login extends Component {
    constructor(props) {
        super(props);
        //this.onLoginClick = this.onLoginClick.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        //this.onKeyPressed = this.onKeyPressed.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { username: '', userpassword: '', message: '', loading: false }
    }

    componentDidMount() {
        document.title = "Login";
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        AuthService.logOut();
        //document.addEventListener("keydown", this.onKeyPressed);
    }

    handleUsernameChange = (e) => {
        e.preventDefault();
        this.setState({ username: e.target.value });
    }
    handlePasswordChange = (e) => {
        e.preventDefault();
        this.setState({ userpassword: e.target.value });
    }

    onKeyPressed(e) {
        e.preventDefault();
        switch (e.keyCode) {
            case 13:
                this.onLogin();
                break;
            default:
                break;
        }
    }


    onLoginClick(e) {
        e.preventDefault();
        this.onLogin();

        //if (!this.state.username || !this.state.userpassword) {
        //    this.setState({ message: 'Please enter all mandatory fields' })
        //    return;
        //}

        //this.setState({ loading: true });
        //AuthService.login(this.state.username, this.state.userpassword).then(
        //    response => {
        //        if (response.code == 200) {
        //            this.props.router.navigate("/dashboard");
        //            window.location.reload();
        //        }
        //        else {
        //            this.setState({ message: response.message, loading: false })
        //        }
        //    },
        //    error => {
        //        const resMessage =
        //            (error.response &&
        //                error.response.data &&
        //                error.response.data.message) || error.message || error.toString();

        //        this.setState({
        //            loading: false,
        //            message: resMessage
        //        });
        //    }
        //);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.username || !this.state.userpassword) {
            this.setState({ message: 'Please enter all mandatory fields' })
            return;
        }

        this.setState({ loading: true, message:'' });
        AuthService.login(this.state.username, this.state.userpassword).then(
            response => {
                if (response.code == 200) {
                    this.props.router.navigate("/dashboard");
                    window.location.reload();
                }
                else {
                    this.setState({ message: response.message, loading: false })
                }
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) || error.message || error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    }

    onLogin() {
        if (!this.state.username || !this.state.userpassword) {
            this.setState({ message: 'Please enter all mandatory fields' })
            return;
        }

        this.setState({ loading: true });
        AuthService.login(this.state.username, this.state.userpassword).then(
            response => {
                if (response.code == 200) {
                    this.props.router.navigate("/dashboard");
                    window.location.reload();
                }
                else {
                    this.setState({ message: response.message, loading: false })
                }
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) || error.message || error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    }

    render() {
        return (
            <div>

                <Container className="loginScreen">
                    <h4>Sign In</h4>
                    <Form className="loginForm" onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="userName">Email<span className="text-danger"> *</span></Label>
                            <Input
                                type="email" autoFocus="autofocus"
                                name="userName" autoComplete="off"
                                id="userName" value={this.state.username} onChange={this.handleUsernameChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="userPassword">Password <span className="text-danger"> *</span></Label>
                            <Input
                                type="password"
                                name="userPassword"
                                id="userPassword"

                                value={this.state.userpassword} onChange={this.handlePasswordChange}
                            />
                        </FormGroup>
                        <div style={{ color: "red" }}>{this.state.message}</div>
                        <br />
                        <Button className="btn btn-primary" type="submit">Submit</Button>
                        {this.state.loading ? <div style={{ display: "table", marginRight: "auto", marginLeft: "auto" }}>Please wait..</div> : ''}
                    </Form>
                </Container>
            </div>
        );
    }
}
export default withRouter(Login);