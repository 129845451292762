import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class NotAuthorize extends Component {

    constructor(props) {
        super(props);        
    }

    render() {
        return (
            <div>
                <Container style={{ marginTop: "120px" }}>
                    <h2 className="text-danger">Access Denied</h2>
                    <p>Access Denied, You don't have permission to access this page.</p>
                </Container>
            </div>
        );
    }
}
