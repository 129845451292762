import React, { Component} from 'react';
import AuthService from "../services/AuthService";

export class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {username: '', role: '', tenant: ''}
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        document.title = "Profile";
        var user = AuthService.getLoggedInUser();
        console.log(user);
        this.setState({ username: user.fullName, role: user.roles[0], tenant: user.tenant })
    }
    render() {
        return (
            <div>
                <div>
                    <h5><i className="fa fa-user" aria-hidden="true"></i> Profile</h5><hr />
                </div>
                <h6>Hi,{this.state.username}</h6><p></p>
                <h6 className="text-danger">Logged in as ,{this.state.role} for <span className="text-primary">{this.state.tenant}</span></h6>               
            </div>            
            );
    }   
}
