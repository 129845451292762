import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class NotFound extends Component {

    constructor(props) {
        super(props);        
    }
    componentDidMount() {        
        document.title = "Not Found";        
    }


    render() {
        return (
            <div>
                <Container style={{ marginTop: "120px" }}>
                    <h1 className="text-danger">Page not found :(</h1>
                </Container>
            </div>
        );
    }
}
