import React, { Component } from 'react';
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
export class Logout extends Component {

    constructor(props) {
        super(props);      
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        document.title = "Log out";
        AuthService.logOut();       
    }

 
    render() {        

        return (           
            <div>
                <h4 className="text-black">
                    <span>You are now successfully logged out..</span>
                </h4>
                <Link to="/login">Login again</Link>
            </div>
        );
    }
}
