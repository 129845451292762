import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import withParamRouter from '../common/WithParamRouter';
import AuthService from "../services/AuthService";

class EditTenant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true, redirect: '', currentuser: undefined, classname: '', message: '', mask: '********-****-****-****-************',
            tenantid: this.props.params.id, tenant: undefined, isinternal: false
        }
 
        this.changeTenantNameHandler = this.changeTenantNameHandler.bind(this);
        this.changeCallbackHandler = this.changeCallbackHandler.bind(this);
        this.changeSupportEmailHandler = this.changeSupportEmailHandler.bind(this);
        this.updateTenant = this.updateTenant.bind(this);
        this.onShowSecret = this.onShowSecret.bind(this);
        this.onInteralCheck = this.onInteralCheck.bind(this);
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Edit Tenant";
        const user = AuthService.getLoggedInUser();

        if (user) {
            this.setState({ currentuser: user });
            if (!user.roles.includes("SystemAdmin")) this.setState({ redirect: '/denied' });                        
            this.getTenant(this.props.params.id, user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    changeTenantNameHandler = (e) => {
        e.preventDefault();
        let tenant = this.state.tenant;
        tenant.name = e.target.value;
        this.setState({ tenant: tenant });
    }

    changeCallbackHandler = (e) => {
        e.preventDefault();
        let tenant = this.state.tenant;
        tenant.callBackUrl = e.target.value;
        this.setState({ tenant: tenant });
    }

    changeSupportEmailHandler = (e) => {
        e.preventDefault();
        let tenant = this.state.tenant;
        tenant.supportEmail = e.target.value;
        this.setState({ tenant: tenant });        
    }

    changeHeaderHandler = (e) => {
        e.preventDefault();
        let tenant = this.state.tenant;
        tenant.callBackHeaders = e.target.value;
        this.setState({ tenant: tenant });  
    }

 
    onShowSecret() {
        this.setState({mask: this.state.tenant.clientSecret});
    }
    onInteralCheck(val) {
        this.setState({ isinternal: val })
    }

    async getTenant(id, user) {
        const response = await fetch("api/tenants/" + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token
            }
        });

        const data = await response.json();
        if (response.ok) {
            this.setState({ tenant: data })
        }

        else {
            this.setState({ message: data.message });
        }

        this.setState({ loading: false })
    }

    async updateTenant() {
        event.preventDefault();

        if (!this.state.tenant.name || !this.state.tenant.supportEmail) {
            this.setState({ message: 'Please enter all mandatory fields', classname: 'alert alert-danger' })
            return;
        }

        this.setState({ message: 'Processing. please wait..' });
        const user = AuthService.getLoggedInUser();
        const response = await fetch("api/tenants", {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token
            },
            body: JSON.stringify(this.state.tenant)
        });

        var data = await response.json();
        
        if (response.ok) {
            this.setState({ message: data.message, classname: 'alert alert-success' })
        }
        else {
            this.setState({ message: data.message, classname: 'alert alert-danger' })
        }
    }

    renderTenant(tenant) {
        
        return (
            <div>
                <Row>
                    <Col md="12">
                        <h5><i className="fa fa-list" aria-hidden="true"></i> <Link to="/tenants">Tenants</Link> >> Edit Tenant </h5><hr />
                    </Col>
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                    <Col md="12">
                        <Form>
                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-name">Name<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-name" id="txt-name" value={tenant.name}
                                            placeholder="Content Query"
                                            onChange={this.changeTenantNameHandler} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-client-id">Client ID<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-client-id" id="txt-clientid" value={tenant.clientId} disabled />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-client-secret">Client Secret<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-client-secret" id="txt-client-secret" 
                                            value={this.state.mask} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <Button onClick={this.onShowSecret} className="btn btn-sm" style={{ marginTop: "35px" }}>Show</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-supportemail">Support Email<span className="text-danger"> *</span></Label>
                                        <Input type="text" name="txt-supportemail" id="txt-supportemail" value={tenant.supportEmail} 
                                            onChange={this.changeSupportEmailHandler}
                                            placeholder="support@contentquery.io"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="11">
                                    <FormGroup>
                                        <Label for="txt-callbackurl">Notification Url<span className="text-danger"></span></Label>
                                        <Input type="text" name="txt-callbackurl" id="txt-callbackurl" value={tenant.callBackUrl}
                                            onChange={this.changeCallbackHandler} placeholder="https://companydomain.com/notify"
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="11">
                                    <FormGroup>

                                        <Label for="txt-headers">Headers (key:value)<span className="text-danger"></span></Label>
                                        <Input type="textarea" name="txt-headers" id="txt-headers"
                                            placeholder="e.g. x-api-key:5b5dbd32cdb44a4fa9a436c6e4937baf"
                                            onChange={this.changeHeaderHandler}
                                            
                                            value={tenant.callBackHeaders} />
                                    </FormGroup>
                                </Col>
                                <Col md="1">
                                    <Button className="btn btn-sm" style={{ marginTop: "35px" }}>Ping</Button>
                                </Col>
                                <Col md="11">
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="checkbox"
                                                value={tenant.isInternal}
                                                onChange={(e) => this.onInteralCheck(e.target.checked)} /> Internal Client
                                        </Label>
                                    </FormGroup>
                                </Col>

                               
                            </Row>

                            <p></p>

                            <Button onClick={async () => { await this.updateTenant(); }} className="btn btn-primary">Update</Button>
                        </Form>

                        <div className="pt-4"><p><em>{this.state.loading}</em></p></div>
                    </Col>
                </Row>

            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        
        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderTenant(this.state.tenant);
        return (
            <div>                
                {contents}
            </div>
        );        
    }
}

export default withParamRouter(EditTenant)