import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button, Col, Row } from 'reactstrap';
import Autocomplete from 'react-autocomplete';
import AuthService from "../services/AuthService";

const Result = ({ results, message }) => {
    if (results.length == 0) {
        return (
            <div>
                <Row>
                    <Col md="12">
                        <span className='text-danger'>{message}</span>
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div className="mt-3">
            <Row>
                {results.map(asset =>
                    <Col key={asset.key}>
                        <em>H:{asset.height} x W:{asset.width}</em>
                        <div id={asset.id} campaignid={asset.campaignId} >
                            <img src={asset.thumbNailURL}
                                style={{ cursor: "pointer", margin: "10px" }}
                                width={asset.previewWidth}
                                onClick={() => window.open(asset.imageUrl)}
                                height={asset.previewHeight} />
                            <div>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}

const OptionItems = ({ tags, name }) => {
    let items = [];
    tags.map(tag => {
        if (tag.name.toLowerCase() == name.toLowerCase()) {
            items.push(<option key={tag.id} value={tag.value}>{tag.value}</option>);
        }
    })

    return (
        items
    );
}

export class SearchAssets extends Component {

    constructor(props) {
        super(props)
        this.state = {
            autocompletedata: [],
            loadingassetmessage: '',
            message: '',
            campaignid: '',
            tags: [],
            assetlist: [],
            loading: false,
            redirect: null,
            currentuser: undefined,
            value: '',
            searchclass: 'hide-search-box'
        }

        this.onSearch = this.onSearch.bind(this);
        this.handleHtmlControlChange = this.handleHtmlControlChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getCampaignsByKeywords = this.getCampaignsByKeywords.bind(this);
    }

    componentDidMount() {

        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Search Assets";
        const user = AuthService.getLoggedInUser();

        if (user) {
            this.setState({ currentuser: user });
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    onSearch() {

        const user = AuthService.getLoggedInUser();
        var input = { campaignId: this.state.campaignid, categories: this.state.categories }
        this.setState({ loading: true, loadingassetmessage: 'Searching assets...please wait' });
        fetch("api/assets/search", {
            method: "POST",
            body: JSON.stringify(input),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        }).then(response =>

            response.json().then(data => ({
                data: data,
                status: response.status
            })).then(res => {
                this.setState({
                    assetlist: res.data, loading: false, loadingassetmessage: res.data.length > 0 ? '' : 'We can not find the assets you are lookig for.'

                });
            }));
    }

    handleHtmlControlChange = (e) => {
        e.preventDefault();
        this.setState({ campaignid: e.target.value });
        this.getCampaignMetadata(e.target.value);

    }

    handleChange = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute('tagholder');
        let cats = this.state.categories;
        cats.map(t => {
            if (t.key == id) {
                cats = cats.filter(function (item) {
                    return item.key != id
                })
            }
        });

        cats.push({ 'key': id, 'val': e.target.value });
        this.setState({ categories: cats });
    }

    setDefaultCategories(tags) {
        let cats = [];
        const def = ['cqtag1', 'cqtag2', 'cqtag3', 'cqtag4', 'cqtag5', 'cqtag6', 'cqtag7', 'cqtag8', 'cqtag9', 'cqtag10'];
        console.log("tags--", tags);

        def.map(m => {
            let existing = cats.filter(function (item) {
                return item.key.toLowerCase() == m.toLowerCase()
            });

            let cqtags = tags.filter(function (item) {
                return item.name.toLowerCase() == m.toLowerCase()
            });

            console.log("cqtags filter", cqtags);

            if (cqtags.length > 0 && existing.length == 0) {
                cats.push({ 'key': m, 'val': cqtags[0].tagValues[0].value });
            }
        });

        console.log("cats--", cats);

        return cats;
    }

    async getCampaignMetadata(id) {

        if (id.length < 4) {
            return;
        }
        this.setState({ loading: true, message: 'Searching in campaigns..', categories: [], val: '' })

        const user = AuthService.getLoggedInUser();

        const response = await fetch("api/assets/searchmeta/" + id, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });
        const data = await response.json();
        this.setState(
            {
                message: data.length == 0 ? 'Campaign attributes not found for given campaign' : '',
                loading: false,
                tags: data,
                searchclass: data.length == 0 ? 'hide-search-box' : 'show-search-box'
            });

        if (data.length > 0) {
            var cats = this.setDefaultCategories(data);
            this.setState({ categories: cats });
        }
    }

    onChange(e) {
        e.preventDefault();
        this.setState({
            value: e.target.value
        });

        this.getCampaignsByKeywords(e.target.value);
    }

    onSelect(val) {
        this.setState({ value: val, campaignid: val });
        this.getCampaignMetadata(val);
    }

    getCampaignsByKeywords(query) {
        this.setState({ autocompletedata: [] });

        if (query.length < 1) {
            return;
        }

        this.setState({ loading: true, message: 'Loading campaign list' });
        const user = AuthService.getLoggedInUser();

        fetch("api/campaigns/search/" + query, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        }).then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })).then(res => {
                this.setState({
                    autocompletedata: res.data, loading: false,
                    message: res.data.length == 0 ? 'List not available for input campaign' : ''
                });
            }));
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        return (
            <div>
                <div>
                    <h5><i className="fa fa-search" aria-hidden="true"></i> Search</h5><hr />
                </div>
                <Row>
                    <Col md="12" className="autocomplete-wrapper">
                        <Label for="campaignId">Campaign Id</Label>
                        <Autocomplete className="m-4"
                            id='campaignId'
                            value={this.state.value}
                            items={this.state.autocompletedata}
                            getItemValue={item => item.title}
                            renderItem={(item, isHighlighted) =>
                                <div key={item.title} className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                    <img src={item.thumbnail} height="50" width="50" /> {item.title}
                                </div>
                            }

                            onChange={this.onChange}
                            onSelect={this.onSelect}
                            renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                        />
                        <div style={{ color: "red" }}>{this.state.message}</div>
                    </Col>

                    <Col md={12} className={this.state.searchclass} >
                        <p></p>
                        <Form className="bg-light p-3">
                            <Row>
                                {this.state.tags.map(tag =>

                                    <Col md="4" key={tag.name}>
                                        <FormGroup>
                                            <Label for={tag.name}>{tag.name}</Label>
                                            <Input type="select" name={tag.name} id={tag.name} onChange={this.handleChange} tagholder={tag.name}>
                                                <OptionItems tags={tag.tagValues} name={tag.name} />
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}

                                <Col md="4" style={{ marginTop: "30px" }}>
                                    <Button onClick={this.onSearch} className='btn btn-primary'>Search</Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>

                    <Col md={12}>
                        {this.state.loading ? <div style={{ display: "table", marginRight: "auto", marginLeft: "auto" }}>
                            <span className='text-danger'>{this.state.loadingassetmessage}</span></div> :
                            <Result results={this.state.assetlist} message={this.state.loadingassetmessage} />}
                    </Col>
                </Row>
            </div>
        );
    }
}
