import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import AuthService from "../services/AuthService";


export class Assets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assetlist: null, loading: true, hasaccess: false, message: null,
            redirect: null, currentuser: undefined, allassets: null, query: '',
            detection: [], selected: '', asset: undefined
        }
        this.handleChange = this.handleChange.bind(this);
        this.applyBorder = this.applyBorder.bind(this);
        this.drawRect = this.drawRect.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.value.length == 0) {
            this.setState({ assetlist: this.state.allassets })
        }
        else {
            let result = this.findAssetsByTags(this.state.allassets, e.target.value);
            this.setState({ assetlist: result })

        }
    }

    drawRect(box) {
        console.log(box);
    }

    findAssetsByTags(assets, query) {
        let result = [];

        assets.map(function (element) {
            let tags = element.tags.find(o => o.value.startsWith(query));
            console.log("tags", tags);
            if (tags != undefined) {
                result.push(element);
            }
        });

        return result;
    }

    applyBorder(e) {
        e.target.style.borderBottom = '2px solid';
    }

    removeBorder(e) {
        e.target.style.borderBottom = '0px';
    }

    scalePreserveAspectRatio(imgW, imgH, maxW, maxH) {
        return (Math.min((maxW / imgW), (maxH / imgH)));
    }

    loadParams(e, asset) {
        e.target.style.borderBottom = '2px solid';
        this.setState({ selected: asset.imageUrl, asset: asset });
        this.recognizeImage(asset);
    }
    recognizeImage(asset) {
        this.setState({ selected: asset.imageUrl, asset: asset });
        fetch("api/assets/detect", {
            method: "POST",
            body: JSON.stringify(asset),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json())
            .then(data => this.setState({ detection: data, loading: false }));
    }


    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Assets";
        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user, hasaccess: user.roles.includes("Admin") });
            this.getAssets(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    renderAssets(assets) {

        if (!assets) {
            return (
                <div className="mt-5">
                    <Row>
                        <Col md="12" className="alert alert-danger">
                            <span className="text-danger">{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>

                <Row>
                    <Col md="12">
                        <Input id="query" placeholder="Search" onChange={this.handleChange} />
                    </Col>

                </Row>

                <Row>
                    {/*<Col md="8" className="p-2">*/}
                    {/*    <img className="img-fluid ml-2" src={this.state.assetlist[0].imageUrl} />*/}
                    {/*</Col>*/}

                    {/*<Col md="4" className="p-2">*/}
                    {/*    {*/}
                    {/*        this.state.assetlist[0].tags.map(tag =>*/}
                    {/*            <Badge className="m-1">*/}
                    {/*                {tag.value =="0" ? "No CGI" : tag.value}*/}
                    {/*            </Badge>*/}

                    {/*        )*/}
                    {/*    }*/}
                    {/*</Col>*/}

                    <Col md="6" className="pl-4 pr-4 pt-2">
                        <Row>
                            {assets.map(asset =>
                                <Col md="3" className="p-0" key={asset.id}>
                                    <img className="img-fluid"
                                        onMouseOver={this.applyBorder}
                                        onMouseOut={this.removeBorder}
                                        onClick={event => this.loadParams(event, asset)}
                                        style={{ padding: "2px" }}
                                        key={asset.assetcqId} src={asset.thumbNailURL} /><p></p>
                                    {/*{
                                    {/*    asset.tags.map(tag =>*/}
                                    {/*        <small><em> {tag.value} </em></small>*/}
                                    {/*    )*/}
                                    {/*}                                */}
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col md="6" className="pl-4 pr-4 pt-2" >
                        
                        <div>
                            <img src={this.state.selected} className="img-fluid" />
                        </div>
                        <div>
                            {
                                this.state.asset ? this.state.asset.tags.map(tag =>
                                    <div key={tag.value} className="badge badge-primary m-1">{tag.value}</div>
                                ) : ""
                            }
                            {
                                this.state.detection.map((result, index) =>
                                    <div key={index} className="badge badge-info m-1" style={{ cursor: "pointer" }} onClick={() => this.drawRect(result)}>{result.label} : {Math.round(result.confidence * 100)}% </div>
                                )
                            }
                        </div>


                        <div className="mt-1">
                            <Input type="textarea" style={{ height: "200px", fontSize:"12px" }} value={this.state.selected} />
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderAssets(this.state.assetlist);
        return (

            <div>
                <Row>
                    <Col md="12">
                        <h5>
                            Assets({this.state.assetlist ? this.state.assetlist.length : 0})
                        </h5>
                        <hr />
                    </Col>

                    <Col md="12">
                        {contents}
                    </Col>

                </Row>
            </div>
        );
    }

    async getAssets(user) {
        const response = await fetch("api/assets/getall", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });
        const data = await response.json();

        if (response.ok) {
            let result = [];
            data.map(function (element) {
                let res = result.find(o => o.id == element.id);
                if (res == undefined) {
                    result.push(element);
                }
            });

            this.setState({ assetlist: result, allassets: result, loading: false, asset: result[0] })
            this.recognizeImage(result[0])
        }

        else {
            this.setState({ message: data.message, loading: false });
        }
    }
}