import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText, CardSubtitle, CardFooter, Badge, Row, Col, Button } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import withParamRouter from '../common/WithParamRouter';
import AuthService from "../services/AuthService";
import ReactIntense from 'react-intense';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-intense/dist/loader.css';
import 'react-intense/dist/ReactIntense.css';

const EditAssetIcon = props => {

    const navigate = useNavigate();
    const handleClick = (o) => {
        navigate('/editasset', { state: props });
    }
    return (
        <Button title="Edit Asset" className="btn btn-sm btn-primary ml-1" onClick={() => handleClick(props)} >
            <i className="fa fa-edit"></i>
        </Button>)
}

const RenderImageGallery = ({ images }) => {
    var source = [];
    images.map(image => {
        source.push({
            original: image.imageUrl, loading: 'eager',
            description: image.name, thumbnail: image.thumbNailURL,
            thumbnailLabel: image.height + 'x' + image.width, originalTitle: image.name
        });
    });
    return <ImageGallery items={source} thumbnailPosition='right' />;
}

class CampaignAssets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assetlist: null, allassets: null, variations: null, loading: true, hasaccess: false, message: null,
            redirect: null, currentuser: undefined,
            gridclass: 'show-grid-view', galleryclass: 'hide-gallery', copiedClass:'fa fa-copy',
            gallerybutton: 'btn btn-sm pull-right ml-1', gridbutton: 'btn btn-sm pull-right btn-primary'

        }
        this.handleGalleryClick = this.handleGalleryClick.bind(this);
        this.handleGridClick = this.handleGridClick.bind(this);
        this.handleVariation = this.handleVariation.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);
        this.copyUrl = this.copyUrl.bind(this);

    }

    async deleteAsset(id) {
        if (window.confirm('Are you sure you want to remove this asset? It cannot be undone')) {
            const user = AuthService.getLoggedInUser();
            const response = await fetch("api/assets/" + this.props.params.id + "/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + user.token,
                        Tenant: user.tenant
                    }
                });

            if (response.ok) {
                const list = this.state.assetlist.filter(asset => asset.assetcqId !== id);
                this.setState({ assetlist: list });
            }
            else {
                alert('Something went wrong. Unable to delete selected asset, Please try again.');
            }
        }
    }

    handleGridClick = () => {

        this.setState({
            gridclass: 'show-grid-view', galleryclass: 'hide-gallery', gridbutton: 'btn btn-sm pull-right btn-primary',
            gallerybutton: 'btn btn-sm pull-right ml-1'
        });
    };

    handleGalleryClick = () => {
        this.setState({
            gridclass: 'hide-grid-view', galleryclass: 'show-gallery', gridbutton: 'btn btn-sm pull-right',
            gallerybutton: 'btn btn-sm pull-right ml-1 btn-primary',
            variations: this.state.allassets
        });
    };

    handleVariation = (id) => {
        var assets = this.state.allassets;
        var asset = assets.find(a => a.id == id);
        let tmp = [];

        tmp.push(asset);
        asset.variations.map(v => {
            tmp.push(v);
        });

        this.setState({
            variations: tmp, gridclass: 'hide-grid-view', galleryclass: 'show-gallery', gridbutton: 'btn btn-sm pull-right',
            gallerybutton: 'btn btn-sm pull-right ml-1 btn-primary'
        })
    }

    onKeyPressed(e) {        
        switch (e.keyCode) {
            case 27:
                this.setState({
                    gridclass: 'show-grid-view', galleryclass: 'hide-gallery', gridbutton: 'btn btn-sm pull-right btn-primary',
                    gallerybutton: 'btn btn-sm pull-right ml-1'
                });
                break;
            default:
                break;
        }
    }

    copyUrl(e) {       
        let child = e.currentTarget.children;                
        var id = e.currentTarget.getAttribute("img-id");
        var assets = this.state.allassets.filter(w => w.id == id);
        var data = "";
        assets.map(asset => {
            data = data +  asset.height + 'x' + asset.width + ' ' + asset.imageUrl + "\n";
        });
        navigator.clipboard.writeText(data);
        child[0].setAttribute("class", "fa fa-check");

        setTimeout(() => {
            child[0].setAttribute("class", "fa fa-copy");
        }, 300);
    }

    componentDidMount() {

        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Assets";
        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user, hasaccess: !user.roles.includes("Viewer") });
            this.getAssets(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }

        document.addEventListener("keydown", this.onKeyPressed);
    }

    generateKey() {
        const min = 10000;
        const max = 99000;
        return (
            min + Math.random() * (max - min));
    }

    renderAssets(assets) {

        if (!assets) {
            return (
                <div className="mt-5">
                    <Row>
                        <Col md="12" className="alert alert-danger">
                            <span className="text-danger">{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }
        return (
            <div>
                <div>
                    <Row>
                        <Col md="12">
                            <Button onClick={() => this.handleGalleryClick()} className={this.state.gallerybutton}><i className="fa fa-qrcode"></i> Show All {this.state.variations.length}</Button>
                            <Button onClick={() => this.handleGridClick()} className={this.state.gridbutton}><i className="fa fa-list"></i> Grid</Button>
                        </Col>
                    </Row>
                </div>
                <div className={this.state.gridclass}>
                    <Row className="p-2">
                        {assets.map(asset =>
                            <Col md="3" key={this.generateKey()} className="card-btm-margin">
                                <Card id={asset.assetcqId} campaignid={asset.campaignId} className="card-shadow  h-100">
                                    <ReactIntense key={asset.assetcqId} loader='uil-spin-css'
                                        thumbnailSrc={asset.thumbNailURL}
                                        src={asset.imageUrl} title={asset.name}

                                        caption=
                                        {
                                            asset.tags.map(tag =>
                                                <Badge key={tag.id} className="mr-2" pill color="success">
                                                    <small>{tag.name} :{tag.value}</small>
                                                </Badge>
                                            )
                                        }
                                        className='demo-image' vertical={true} horizontal={true} />

                                    <CardBody>
                                        <CardTitle>
                                            <h6><Link title={asset.name} to="#" style={{ textDecoration: "none" }}>{asset.name.length > 25 ? asset.name.substring(1, 24) + "..." : asset.name}</Link></h6>
                                        </CardTitle>

                                        <CardSubtitle className="mb-2 text-muted">
                                            <span className="pull-right"><small><b> Rank: {asset.rank}</b> </small></span>
                                            <span className="pull-left">
                                                <small>H:{asset.height} x W:{asset.width} (<em>{asset.aspectRatio}</em>)</small>
                                            </span>
                                        </CardSubtitle>

                                        <CardText>
                                            {
                                                asset.tags.map(tag =>
                                                    <Badge key={tag.id} className="mr-2" pill color="success">
                                                        <small>{tag.name} :{tag.value}</small>
                                                    </Badge>
                                                )
                                            }
                                        </CardText>
                                    </CardBody>

                                    <CardFooter style={{ background: "white" }}>
                                        <span className="pull-left">
                                            {
                                                asset.variations.length > 0 ? <Button className="btn btn-sm btn-info" title="Variations" onClick={() => this.handleVariation(asset.id)}>
                                                    {asset.variations.length} <i className="fa fa-paint-brush" aria-hidden="true"></i> </Button>
                                                    : ""
                                            }
                                        </span>
                                        <div className="card-button-align">
                                            <span>
                                                <Button className="btn btn-sm btn-dark" img-id={asset.id}
                                                    title="Copy all image Urls" onClick={this.copyUrl}>
                                                    <i className={this.state.copiedClass} ></i>
                                                </Button>
                                            </span>
                                            {this.state.hasaccess && (
                                                <span>
                                                    <EditAssetIcon data={{ id: asset.id, url: asset.thumbNailURL, tags: asset.tags, cid: this.props.params.id }} />
                                                    <Button title="Delete Asset" className="btn btn-sm btn-danger ml-1" onClick={async () => { await this.deleteAsset(asset.assetcqId); }}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </span>
                                            )}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
                <div className={this.state.galleryclass}>
                    
                    <Row className="p-3">
                        <Col md="12" className="mb-5 card-shadow p-1" onKeyDown={this.onKeyPressed}>
                            <RenderImageGallery images={this.state.variations} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        document.body.setAttribute("style", "display:block");
        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderAssets(this.state.assetlist);
        return (

            <div>
                <Row>
                    <Col md="12">
                        <h5>
                            <i className="fa fa-bullhorn" aria-hidden="true"></i> <Link to="/campaigns">Campaigns</Link> > <Link to={`/assets/${this.props.params.id}`}> {this.props.params.id} </Link> >Assets({this.state.assetlist ? this.state.assetlist.length : 0})
                        </h5>
                        <hr />
                    </Col>

                    <Col md="12">
                        {contents}
                    </Col>
                </Row>
            </div>
        );
    }

    async getAssets(user) {
        const response = await fetch("api/assets/" + this.props.params.id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });

        const data = await response.json();

        if (response.ok) {
            this.setState({ assetlist: data, loading: false });
            let tmp = [];

            data.map(a => {
                tmp.push(a);
                a.variations.map(v => {
                    tmp.push(v);
                });
            });
            this.setState({ allassets: tmp, variations: tmp })
        }

        else {
            this.setState({ message: data.message, loading: false });
        }
    }
}

export default withParamRouter(CampaignAssets)