import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Navigate, Link } from 'react-router-dom';
import withParamRouter from '../common/WithParamRouter';
import AuthService from "../services/AuthService";


const generateUUID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

class EditAsset extends Component {

    constructor(props) {
        super(props);
        const data = this.props.location.state.data;

        this.state = {
            loading: '',
            cid: data.cid,
            id: data.id,
            url: data.url,
            redirect: null,
            currentuser: undefined,
            message: '',
            asset: data,
            classname: '',
            tags: data.tags
        }

        this.updateAsset = this.updateAsset.bind(this);
        this.handleChangeKey = this.handleChangeKey.bind(this);
        this.handleChangeNameValue = this.handleChangeNameValue.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        document.title = "Edit Asset";
        const user = AuthService.getLoggedInUser();
        this.setState({ currentuser: user });
        if (user.roles.includes("Viewer")) this.setState({ redirect: '/denied' });

        console.log("tags", this.state.tags);
    }

    handleChangeKey(e) {
        let tags = this.state.tags;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].nameKey == e.target.name) {
                tags[i].name = e.target.value;
            }
        }

        this.setState({ tags: tags });
    }

    handleChangeNameValue(e) {
        let tags = this.state.tags;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].nameValue == e.target.name) {

                tags[i].value = e.target.value;
            }
        }

        this.setState({ tags: tags });
    }

    handleAddTag() {        
        let tags = this.state.tags;
        if (tags.length == 10) {

            return;
        }
        var uid = generateUUID();

        var tag = {
            id: uid, isDeleted: false, isNew: true,
            key: 'CQTag' + (this.state.tags.length + 1), name: '',
            nameKey: 'key-' + uid, nameValue: 'value-' + uid, value: ''
        }
        tags.push(tag);
        this.setState({ tags: tags });
    }

    deleteRow(id) {
        var tags = this.state.tags;
        for (var i = 0; i < tags.length; i++) {
            if (tags[i].id == id) {
                if (tags[i].isNew == true) {
                    var tmp = tags.filter(function (item) {
                        return item.id !== tags[i].id
                    })
                    this.setState({ tags: tmp });
                    return;
                }
                else {
                    tags[i].isdeleted = true;                    
                    this.setState({ tags: tags });
                    return;
                }
            }
        }
    }

    undoDelete(id) {
        var tags = this.state.tags;
        for (var i = 0; i < tags.length; i++) {
            if (tags[i].id == id) {
                tags[i].isdeleted = false;
            }
        }

        this.setState({ tags: tags });
    }

    async updateAsset() {
        event.preventDefault();

        //var tags = this.state.tags.filter(function (tag) {
        //    return !tag.isdeleted
        //});

        let model = { campaignId: this.state.cid, assetId: this.state.id, tags: this.state.tags }
        this.setState({ loading: 'Updating asset tags. please wait..' });
        const user = AuthService.getLoggedInUser();

        const response = await fetch("api/assets", {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });

        var data = await response.json();

        if (response.ok) {
            this.setState({ loading: '', message: data.message, classname: 'alert alert-success' })
        }
        else {
            this.setState({ message: data.message, classname: 'alert alert-danger', loading: '' })
        }
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        return (
            <div>
                <Row>
                    <Col md="12">
                        <h5>
                            <i className="fa fa-bullhorn" aria-hidden="true"></i> <Link to="/campaigns">Campaigns</Link> > <Link to={{ pathname: `/assets/${this.state.cid}` }}>Assets</Link> > Update Assets
                        </h5>
                        <hr />
                    </Col>

                    <Col md="3">
                        <img src={this.state.url} />
                    </Col>

                    <Col md="8">
                        <Row>
                            <Col md="12">
                                <Form>
                                    {
                                        this.state.tags.map(tag =>
                                            <Row key={tag.id}>
                                                <Col md={1} className="mt-2">
                                                    <span><b>{tag.key}</b></span>
                                                </Col>

                                                <Col md={5}>                                                    
                                                    <FormGroup>                                                        
                                                        <Input
                                                            className={tag.isdeleted ? "text-light bg-danger" : ""}
                                                            type="text" tagname={tag.key} name={tag.nameKey} id={tag.nameKey} value={tag.name}
                                                            onChange={this.handleChangeKey} />
                                                    </FormGroup>
                                                </Col>

                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Input
                                                            className={tag.isdeleted ? "text-light bg-danger" : ""}
                                                            type="text" tagname={tag.key} name={tag.nameValue} id={tag.nameValue} value={tag.value}
                                                            onChange={this.handleChangeNameValue} />
                                                    </FormGroup>
                                                </Col>

                                                <Col md={1}>
                                                    <Button style={{ marginTop: "5px" }}
                                                        onClick={tag.isdeleted ? () => this.undoDelete(tag.id) : () => this.deleteRow(tag.id)}
                                                        className={tag.isdeleted ? "btn btn-sm btn-primary" : "btn btn-sm btn-danger"}>
                                                        <i className={tag.isdeleted ? "fa fa-undo" : "fa fa-trash"}></i>
                                                    </ Button>
                                                </Col>

                                            </Row>
                                        )
                                    }
                                    <Row key="0000-00000-0000-000-00002">
                                        <Col md={11}>
                                            <Button title="Add new tag" onClick={this.handleAddTag} className="btn btn btn-sm btn-secondary pull-right">+</Button>
                                        </Col>
                                    </Row>

                                    <Button onClick={async () => { await this.updateAsset(); }} className="btn btn-primary mt-1">Update</Button>

                                </Form>

                                <div className="pt-4"><p><em>{this.state.loading}</em></p></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="p-3">
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withParamRouter(EditAsset)