import axios from "axios";
class AuthService {

    login(username, password) {
        return axios
            .post('userlogin', {
                username,
                password
            })
            .then(response => {                 
                if (response.data.token) {                    
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logOut() {
        localStorage.removeItem("user");
    }

    getLoggedInUser() {
        const data = localStorage.getItem('user');        
        if (data)
            return JSON.parse(data);
        else
            return undefined;
    }

    registerUser(user) {
        return axios.post(API_URL + "register", user);
    }
}

export default new AuthService();