import React, { Component } from 'react';
import { Navigate, Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Row, Col, Input } from 'reactstrap'
import AuthService from "../services/AuthService";

export class Campaigns extends Component {
    static displayName = Campaigns.name;

    constructor(props) {
        super(props);
        this.state = {
            campaignlist: null, loading: true, currentuser: undefined, redirect: null,
            message: null,  allcampaigns:[]
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {        
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");

        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user });
            document.title = "Campaigns";
            this.getCampaigns(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    handleChange(e) {
       
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.value.length == 0) {
            this.setState({ campaignlist: this.state.allcampaigns })
        }
        else {
            let result = this.findCampaignByName(this.state.allcampaigns, e.target.value);
            console.log(result);

            this.setState({ campaignlist: result })
        }
    }

    findCampaignByName(campaigns, query) {       
        let result = [];
        campaigns.map(function (element) {
            if (element.id.toLowerCase().startsWith(query.toLowerCase())){
                result.push(element);
            }
        });

        return result;
    }

    renderCampaigns(campaigns) {
         
        if (campaigns == undefined) {
            return (
                <div>
                    <Row className="p-3">
                        <Col md="12" className="alert alert-danger">
                            <span className="text-danger">{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <div md="12" className={this.state.gridclass}>
                    <Row>
                        <Col md="12">
                            <Input id="query" placeholder="Search" onChange={this.handleChange} />
                        </Col>

                    </Row>

                    <Row className="p-2">
                        {campaigns.map(campaign =>
                            <Col md="3" key={campaign.id} className="card-btm-margin">
                                <Card className="card-shadow h-100 text-center p-1">
                                    <Link to={"/assets/" + campaign.id} state={{ id: campaign.id }}>
                                        <img
                                        style={{ cursor: "pointer", textDecoration: "none" }}
                                        src={campaign.thumbNailURL} /></Link>

                                    <CardBody>
                                        <CardTitle tag="h6">
                                            {campaign.id}                                            
                                        </CardTitle>
                                    </CardBody>

                                    <div className='badge badge-primary m-1'>
                                        {campaign.assetCount} Assets
                                    </div>

                                    
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderCampaigns(this.state.campaignlist);
        return (
            <div>
                <h5><i className="fa fa-bullhorn" aria-hidden="true"></i> Campaigns
                        ({this.state.campaignlist ? this.state.campaignlist.length : 0})</h5><hr />
                {contents}
            </div>
        );
    }

    async getCampaigns(user) {
        const response = await fetch("api/campaigns", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });
        const data = await response.json();

        if (response.ok) {
            this.setState({ campaignlist: data, loading: false, allcampaigns:data })
        }
        else {
            this.setState({ message: data.message, loading: false });
        }
    }    
}