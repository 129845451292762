import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { Navigate, Link } from "react-router-dom";
import AuthService from "../services/AuthService";
import { Chart } from "react-google-charts";

export class Dashboard extends Component {

    constructor(props) {
        super(props);        
        this.state = { analytics: null, loading: true, redirect: null, currentuser: undefined, message: '' }
    }

    componentDidMount() {

        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        

        const user = AuthService.getLoggedInUser();        
        if (user) {
            this.setState({ currentuser: user });
            document.title = "Dashboard";
            this.getDashBoardItems(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    renderDashboard(itm) {        
        if (!itm) {
            return (
                <div>
                    <Row className="p-3">
                        <Col md="12" className="alert alert-danger">
                            <span className="text-danger">{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>               
                <Row>
                    <Col xs="6">
                        <Card tag={Link} to={"/campaigns"} style={{ textDecoration: "none" }} className="my-2" color="primary" inverse>      
                            <CardHeader>
                                Campaigns
                            </CardHeader>                            
                            <CardBody>
                                <CardTitle tag="h1">
                                    {itm.campaignCount}
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card className="my-2" color="success" inverse>
                            <CardHeader>
                                Assets
                            </CardHeader>   
                            <CardBody>
                                <CardTitle tag="h1">
                                    {itm.assetCount}
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card className="my-2">                           
                            <CardBody>
                                <Chart
                                    chartType="Bar"
                                    width="100%"
                                    height="400px"
                                    data={itm.barChart}
                                    options={{
                                        chart: {
                                            title: "Campaigns & Assets",
                                            subtitle: "Campaigns and assets each month.",
                                        },
                                    }}
                                />
                            </CardBody>
                        </Card>

                       
                    </Col>

                    <Col md="6">

                        <Card className="my-2">

                            <CardBody>
                                <Chart
                                    chartType="PieChart"
                                    width="100%"
                                    height="400px"
                                    data={itm.piChart}
                                    options={{
                                        chart: {
                                            title: "Campaigns & Assets",
                                            subtitle: "Campaigns and assets each month.",
                                        },
                                    }}
                                />
                            </CardBody>
                        </Card>


                    </Col>

                </Row>
            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderDashboard(this.state.analytics);
        return (
            <div>
                <h5><i className="fa fa-home fa-fw" aria-hidden="true"></i> Dashboard</h5><hr />
                {contents}
            </div>
        );
    }

    async getDashBoardItems(user) {

        const response = await fetch("api/dashboard", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });

        const data = await response.json();
        if (response.ok) {
            this.setState({ analytics: data, loading: false })
        }
        else {
            this.setState({ message: data.message, loading: false });                        
        }
    }
}