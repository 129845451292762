import React, { Component } from 'react';
import { Row, Col, Table, Button } from 'reactstrap';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import AuthService from "../services/AuthService";

const AddButton = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/add-tenant');
    }
    return <button className="btn btn-dark pull-right m-2" onClick={handleClick}>Add Tenant</button>;
}

export class Tenants extends Component {

    constructor(props) {
        super(props);
        this.state = { tenantlList: [], loading: true, redirect: null, currentuser: undefined, message: '', classname: '' }
    }

    componentDidMount() {
        var _main = document.getElementById("_main");
        _main.setAttribute("class", "container");
        document.title = "Tenants";
        const user = AuthService.getLoggedInUser();
        if (user) {
            this.setState({ currentuser: user, });
            if (!user.roles.includes("SystemAdmin")) this.setState({ redirect: '/denied' });
            if (!this.state.redirect) this.getTenants(user);
        }
        else {
            this.setState({ redirect: '/login' });
        }
    }

    renderTenants(tenants) {

        if (tenants.length == 0) {
            return (
                <div>
                    <Row>
                        <p></p>
                        <Col md="12" className={this.state.classname}>
                            <span>{this.state.message}</span>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <Row>
                    <p></p>
                    <Col md="12" className={this.state.classname}>
                        <span>{this.state.message}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <AddButton />
                    </Col>
                    <Col md="12">
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Support Email</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tenants.map(tenant =>
                                    <tr key={tenant.id}>
                                        <td>{tenant.name}</td>
                                        <td>{tenant.supportEmail}</td>
                                        <td>{tenant.isActive == true ? "Active" : "Disabled"}</td>

                                        <td align="right">
                                            <Link className="btn btn-sm btn-primary ml-1" to={"/edit-tenant/" + tenant.id} state={{ id: tenant.id }}><i className="fa fa-edit"></i></Link>
                                            <Button className="btn btn-sm btn-danger ml-1" onClick={async () => { await this.deleteTenant(tenant.id); }}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to='/denied' />
        }

        let contents = this.state.loading
            ? <p><em>Loading..</em></p>
            : this.renderTenants(this.state.tenantlList);
        return (
            <div>
                <h5><i className="fa fa-list" aria-hidden="true"></i> Tenants ({this.state.tenantlList ? this.state.tenantlList.length : 0})</h5><hr />
                {contents}
            </div>
        );
    }

    async getTenants(user) {
        const response = await fetch("api/tenants", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + user.token,
                Tenant: user.tenant
            }
        });
        const data = await response.json();

        if (response.ok) {
            this.setState({ tenantlList: data, loading: false })
        }
        else {
            this.setState({ message: data.message, loading: false, classname:'alert alert-danger' });
        }

    }

    async deleteTenant(id) {
        if (window.confirm('Are you sure you want to remove this Tenant?')) {
            const user = AuthService.getLoggedInUser();
            const response = await fetch("api/tenants/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + user.token
                    }
                });

            if (response.ok) {
                const list = this.state.tenantlList.filter(tenant => tenant.id !== id);
                this.setState({ classname: 'alert alert-sucess', message: response.data, tenantlList: list });
            }
            else {
                this.setState({ classname: 'alert alert-danger', message: 'Something went wrong. Unable to delete selected Tenant, Please try again.' });
            }
        }
    }
}